<template lang="">
    <div>
        <Add />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'İş Ortağı Ekle', route: '/is-ortaklari-ekle' },
        ]);
    },
    components:{
        Add: () => import("@/components/isortaklari/Addisortaklari")
    }
}
</script>
<style lang="">
    
</style>